import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Space } from 'antd';

export const SadaSearchBarResult = styled(Space)`
  display: flex;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  padding: ${spacings.sm} ${spacings.md};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.light_gray};
  }
  &:hover {
    background: ${colors.almost_white};
  }
`;
