import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Table } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';

export const SadaTable = styled(Table)`
  width: 100%;
  .ant-table-content {
    & > table {
      &,
      & > thead > tr:first-of-type th:first-of-type {
        border-radius: 0;
      }
    }
  }
  .ant-table-cell {
    padding: ${spacings.sm};
    &:first-of-type {
      padding-left: ${spacings.xl};
    }
    &:last-of-type {
      padding-right: ${spacings.xl};
    }
    border-bottom: 1px solid ${colors.light_gray};
  }
  th.ant-table-cell {
    padding: ${spacings.xs} ${spacings.sm};
    border-top: 1px solid ${colors.light_gray};
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 400;
    color: ${colors.dark_gray};
    &:before {
      display: none;
    }
  }
`;

export const SadaTableRow = (props) => {
  const empty = props.className === 'ant-table-placeholder';
  return (
    <motion.tr
      {...props}
      key={props['data-row-key']}
      {...(empty
        ? {}
        : {
            animate: { y: 0, opacity: 1 },
            initial: { y: -10, opacity: 0 },
          })}
    />
  );
};

export const SadaTableWrapper = ({ children, ...props }) => (
  <tbody {...props}>
    {children[0]}
    <AnimatePresence
      exitBeforeEnter={
        children[1]?.props?.className === 'ant-table-placeholder'
      }
    >
      {children[1]}
    </AnimatePresence>
  </tbody>
);
