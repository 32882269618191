import { SadaKComment } from './styles';
import type IEmployee from '@types/employee.type';
import firebase from 'firebase';
import { Comment, Input, Popconfirm } from 'antd';
import KAvatar from '@components/k-avatar';
import { humanTimestampDateTime } from '@utils/date.utils';
import useAuth from '@hooks/auth.hook';
import { differenceInDays } from 'date-fns';
import fsOrm from '@services/firestore.service';
import { useState } from 'react';

type Props = {
  employee: IEmployee,
  text: string,
  timestamp: firebase.firestore.Timestamp,
  id: string,
  caseId: string,
};

const KComment = ({ employee, text, timestamp, caseId, id }: Props) => {
  const commentRef = fsOrm.cases(caseId).ref().collection('comments').doc(id);
  const [editing, setEditing] = useState(false);
  const [editText, setEditText] = useState(text);
  const [user] = useAuth();
  const deleteComment = () => {
    commentRef.delete();
  };
  const updateComment = () => {
    commentRef.update({ text: editText });
    setEditing(false);
  };
  const actions = user.email === employee.email &&
    differenceInDays(timestamp.toDate(), new Date()) < 1 && [
      <span
        onClick={() => {
          setEditing(true);
        }}
      >
        Edit
      </span>,
      <Popconfirm onConfirm={deleteComment} title="Are you sure?">
        <span>Delete</span>
      </Popconfirm>,
    ];
  return (
    <SadaKComment>
      <Comment
        avatar={<KAvatar {...employee} size="small" />}
        author={employee?.name}
        content={
          editing ? (
            <Input.TextArea
              autoFocus
              onBlur={() => {
                setEditing(false);
                setEditText(text);
              }}
              value={editText}
              onChange={({ target }) => {
                setEditText(target.value);
              }}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  updateComment();
                }
              }}
            />
          ) : (
            text
          )
        }
        datetime={humanTimestampDateTime(timestamp)}
        actions={!editing && actions}
      />
    </SadaKComment>
  );
};

export default KComment;
