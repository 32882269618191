import KModal from '../k-modal';
import { Form, ModalProps } from 'antd';
import KForm from '@components/k-form';
import { emailPattern, phonePattern } from '@utils/form.utils';
import useModal from '@hooks/modal.hook';
import MaskedInput from 'antd-mask-input';
import fsOrm from '@services/firestore.service';
import { useState } from 'react';
import functionsService from '@services/functions.service';

type Props = ModalProps & {};

const ApplicantModal = ({ ...props }: Props) => {
  const { visible, close } = useModal('applicant');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (v) => {
    setLoading(true);
    const applicant = await fsOrm.applicants(v.email).create(v);
    await functionsService.sendInviteApplicant(
      form.getFieldsValue(),
      'Invite sent',
    );
    await fsOrm.setStatus('invited', applicant);
    close();
    setLoading(false);
  };

  return (
    <KModal
      visible={visible}
      {...props}
      title={'Invite Applicant'}
      okText="Send Invite"
      onCancel={close}
      destroyOnClose
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <KForm
        form={form}
        onFinish={onSubmit}
        validateTrigger="onBlur"
        idKey={'email'}
        fields={[
          {
            label: 'Name',
            rules: [{ required: true, message: 'Name is required!' }],
            fieldKey: 'name',
            name: 'name',
          },
          {
            label: 'Email',
            rules: [
              { required: true, message: 'Email is required!' },
              {
                pattern: emailPattern,
                message: 'Invalid Email',
              },
            ],
            fieldKey: 'email',
            name: 'email',
          },
          {
            label: 'Phone',
            rules: [
              {
                pattern: phonePattern,
                message: 'Invalid Phone',
              },
            ],
            fieldKey: 'phone',
            name: 'phone',
            component: MaskedInput,
            componentProps: {
              mask: '(111) 111-1111',
            },
          },
        ]}
      />
    </KModal>
  );
};

export default ApplicantModal;
