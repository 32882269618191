import { SadaSearchBarResults } from './styles';
import { type ICase } from '@types/case.type';
import { type IEmployee } from '@types/employee.type';
import { type IApplicant } from '@types/applicant.type';
import React from 'react';
import { Divider } from 'antd';
import SearchBarResult from './search-bar-result';
import { navigate } from 'hookrouter';
import KEmpty from '@components/k-empty';
import { ReactComponent as EmptySearch } from '@/assets/images/empty-search.svg';

type Props = {
  cases: [ICase],
  employees: [IEmployee],
  applicants: [IApplicant],
  loading: Boolean,
  onSelect: Function,
};

const SearchBarResults = ({
  cases,
  employees,
  applicants,
  loading,
  onSelect = () => {},
}: Props) => {
  const select = (path) => () => {
    navigate(path);
    onSelect();
  };
  return (
    <SadaSearchBarResults>
      {!!cases?.length && (
        <>
          <Divider orientation="left">Cases ({cases.length})</Divider>
          <div>
            {cases.map(({ id, service, applicant }) => (
              <SearchBarResult
                onClick={select(`/cases/${id}`)}
                key={id}
                title={`#${id}`}
                photo={applicant?.photo}
                description={applicant?.name}
              />
            ))}
          </div>
        </>
      )}
      {!!employees?.length && (
        <>
          <Divider orientation="left">Employees ({employees.length})</Divider>
          <div>
            {employees.map(({ email, name, photo }) => (
              <SearchBarResult
                onClick={select(`/employees/${email}`)}
                key={email}
                title={name || '-'}
                photo={photo}
                description={email}
              />
            ))}
          </div>
        </>
      )}
      {!!applicants?.length && (
        <>
          <Divider orientation="left">Applicants ({applicants.length})</Divider>
          <div>
            {applicants.map(({ email, name, photo }) => (
              <SearchBarResult
                onClick={select(`/applicants/${email}`)}
                key={email}
                title={name || '-'}
                photo={photo}
                description={email}
              />
            ))}
          </div>
        </>
      )}
      {!cases?.length && !employees?.length && !applicants?.length && (
        <KEmpty image={<EmptySearch />} description="Nothing found" />
      )}
    </SadaSearchBarResults>
  );
};

export default SearchBarResults;
