import ApplicantCard from '@components/cards/applicant-card';
import KBreadcrumbs from '@components/k-breadcrumbs';
import KHeading from '@components/k-heading';
import useData from '@hooks/data.hook';
import { Col, Row, Space } from 'antd';
import { SadaApplicant } from './styles';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import MeetCardWrapper from '@components/meet-card-wrapper';
import CaseTable from '@components/case-table';
import { navigate } from 'hookrouter';

type Props = { id: String };

const Applicant = ({ id }: Props) => {
  const [data, loading] = useData('applicants', id);

  const query = firebase
    .firestore()
    .collection('cases')
    .where('applicant', '==', fsOrm.applicants(id).ref());

  return (
    <SadaApplicant>
      <KBreadcrumbs
        items={[
          { label: 'Applicants', href: '/applicants' },
          { label: data?.name, loading },
        ]}
      />
      <Space direction="vertical" size={24}>
        <Row gutter={40}>
          <Col xs={24} sm={14}>
            <ApplicantCard loading={loading} applicant={data} />
          </Col>
          <Col xs={24} sm={10}>
            <MeetCardWrapper applicant={id} />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <KHeading title="Applicant Cases" />
            <CaseTable
              query={query}
              onRowClick={(id) => {
                navigate(`/cases/${id}`);
              }}
            />
          </Col>
        </Row>
      </Space>
    </SadaApplicant>
  );
};

export default Applicant;
