import NameWithAvatar from '@components/name-with-avatar';
import useAuth from '@hooks/auth.hook';
import useData from '@hooks/data.hook';
import useModal from '@hooks/modal.hook';
import fsOrm from '@services/firestore.service';
import { Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import KModal from '../k-modal';

const CommentModal = () => {
  const { close, data, visible } = useModal('comment');
  const [user] = useAuth();
  const [employee] = useData('employees', user.email);
  const [text, setText] = useState('');
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref?.current?.focus();
    }, 100);
  }, [visible]);
  const onSubmit = async () => {
    await fsOrm.addComment(text, fsOrm.cases(data?.caseId).ref(), user.email);
    setText('');
    close();
  };
  return (
    <KModal
      visible={visible}
      title={<NameWithAvatar {...employee} />}
      okText="Submit"
      onCancel={() => {
        close();
      }}
      onOk={onSubmit}
      destroyOnClose
      okButtonProps={{ disabled: !text }}
    >
      <Input.TextArea
        ref={ref}
        onChange={({ target }) => {
          setText(target.value);
        }}
        value={text}
      />
    </KModal>
  );
};

export default CommentModal;
