import KHeading from '@components/k-heading';
import useList from '@hooks/list.hook';
import { AddUser } from 'react-iconly';
import { SadaApplicants } from './styles';
import type IApplicant from '@types/applicant.type';
import KDataTable from '@components/k-data-table';
import config from './config';
import { Col, Row } from 'antd';
import useModal from '@hooks/modal.hook';
import { navigate } from 'hookrouter';

const Applicants = () => {
  const { open } = useModal('applicant');
  const [dataSource, loading]: [[IApplicant]] = useList('applicants');
  return (
    <SadaApplicants>
      <Row>
        <Col sm={24}>
          <KHeading title="Applicants" icon={<AddUser />} xl onAction={open} />
          <KDataTable
            config={{
              ...config,
              loading,
              dataSource,
              rowKey: 'email',
              onRow: ({ email }) => ({
                onClick() {
                  navigate(`/applicants/${email}`);
                },
              }),
            }}
            filters
          />
        </Col>
      </Row>
    </SadaApplicants>
  );
};

export default Applicants;
