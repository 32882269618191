import { SadaRemoteStatusTag } from './styles';
import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import * as R from 'ramda';

type Props = {
  collection: String,
  id: String,
  large: Boolean,
  md: Boolean,
  colRef: firebase.firestore.DocumentReference,
};

const config = {
  invited: { color: 'warning', text: 'Invite sent' },
  active: { color: 'success', text: 'Active' },
  pending: { color: 'warning', text: 'Pending' },
  booked: { color: 'success', text: 'Booked', flat: true },
};

const RemoteStatusTag = ({
  collection,
  id,
  large,
  colRef,
  flat,
  md,
}: Props) => {
  const [value] = useCollectionData(
    (colRef || firebase.firestore().collection(collection).doc(id))
      .collection('statuses')
      .orderBy('timestamp', 'desc')
      .limit(1),
  );
  const current = R.path([0, 'status'])(value);
  const status = config[current];
  return <SadaRemoteStatusTag {...status} large={large} flat={flat} md={md} />;
};

export default RemoteStatusTag;
