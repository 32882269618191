import { SadaKSchedule } from './styles';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import firebase from 'firebase';
import useFs from '@hooks/fs.hook';
import { useState } from 'react';
import { endOfDay, endOfWeek, setHours, startOfDay, startOfHour } from 'date-fns';
import * as R from 'ramda';
import { Spin } from 'antd';
import KScheduleEvent from './k-schedule-event';
import KHeading from '@components/k-heading';
import CalendarSelect from '@components/calendar-select';
import fsOrm from '@services/firestore.service';
import useAuth from '@hooks/auth.hook';

type Props = { where: Array };
const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const KSchedule = ({ initial }: Props) => {
  const [selected, setSelected] = useState(initial);
  const [start, setStart] = useState(startOfWeek(new Date()));
  const [end, setEnd] = useState(endOfWeek(new Date()));
  const [event, setEvent] = useState(null);

  let query = firebase
    .firestore()
    .collection('appointments')
    .where('start', '>', firebase.firestore.Timestamp.fromDate(start))
    .where('start', '<', firebase.firestore.Timestamp.fromDate(end));
  const [user] = useAuth();
  if (selected === 'employee') {
    query = query.where('employee', '==', fsOrm.employees(user.email || 'null').ref());
  }
  const [data = [], loading] = useFs({
    query,
    populate: ['employee', 'applicant'],
  });
  const events = data?.map((apt) => ({
    start: apt?.start?.toDate(),
    end: apt?.end?.toDate(),
    appointment: apt,
    active: apt?.id === event?.appointment?.id,
  }));
  return (
    <SadaKSchedule>
      <Spin spinning={loading}>
        <KHeading
          title="Schedule"
          xl
          extra={<CalendarSelect selected={selected} setSelected={setSelected} />}
        />
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          onSelectSlot={console.log}
          defaultView="week"
          popup={true}
          scrollToTime={startOfHour(setHours(new Date(), 8))}
          views={['month', 'week', 'day']}
          onRangeChange={(range) => {
            setStart(startOfDay(range?.start || R.head(range)));
            setEnd(endOfDay(range?.end || R.last(range)));
          }}
          events={events || []}
          components={{
            event: (props) => (
              <KScheduleEvent
                {...props}
                onClose={() => {
                  setEvent(null);
                }}
              />
            ),
          }}
          onSelectEvent={setEvent}
        />
      </Spin>
    </SadaKSchedule>
  );
};

export default KSchedule;
