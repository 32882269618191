import { colors } from '@constants/theme/colors.config';
import { Menu } from 'antd';
import styled from '@emotion/styled';

export const SadaCalendarSelect = styled.div`
  .ant-btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    svg {
      transform: translateX(6px);
    }
  }
`;

export const MenuItemActive = styled(Menu.Item)`
  ${({ active }) => (active ? `color: ${colors.blue};` : '')}
`;
