import { Avatar, Select, SelectProps } from 'antd';
import type IService from '@/types/service.type';
import useAvailability from '@hooks/availability.hook';
import KSelect from '@components/k-select';
import { humanTime } from '@utils/date.utils';
import { UISpaceBox } from '@components/ui/layout';
import KRef from '@components/k-ref';
import KAvatar from '@components/k-avatar';
import fsOrm from '@services/firestore.service';
import * as R from 'ramda';

type Props = SelectProps & {
  service: IService,
  date: Date,
  onChange: Function,
};

const Users = ({ employees }) => (
  <Avatar.Group maxCount={3} size="small">
    {employees.map((v) => (
      <KRef dataRef={fsOrm.employees(v).ref()} key={v}>
        {(employee, idx) => (
          <KAvatar
            {...(employee ? R.pick(['photo', 'name'])(employee) : {})}
            key={employee?.email || idx}
          />
        )}
      </KRef>
    ))}
  </Avatar.Group>
);

const AppointmentTimeSelect = ({
  date,
  service,
  onChange,
  ...props
}: Props) => {
  const [data, loading] = useAvailability(service?.id, date);
  return (
    <KSelect
      loading={loading}
      disabled={loading}
      onSelect={(v) => {
        onChange(data.find(R.propEq('start', v)));
      }}
    >
      {data.map((slot) => (
        <Select.Option key={slot.start}>
          <UISpaceBox>
            {humanTime(new Date(slot.start))} - {humanTime(new Date(slot.end))}
            <Users employees={slot.employees} />
          </UISpaceBox>
        </Select.Option>
      ))}
    </KSelect>
  );
};

export default AppointmentTimeSelect;
