import useList from '@hooks/list.hook';
import { Select, Space, SelectProps } from 'antd';
import type IApplicant from '@types/service.type';
import KSelect from '@components/k-select';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import Avatar from 'antd/lib/avatar/avatar';
import { getInitials } from '@utils/user.utils';

type Props = {
  value: firebase.firestore.DocumentReference,
  onChange: () => {},
} & SelectProps;

const ApplicantSelect = ({ value, onChange = () => {}, ...props }: Props) => {
  const [data = [], loading]: [IApplicant] = useList('applicants');
  return (
    <KSelect
      loading={loading}
      onChange={(v) => {
        onChange(fsOrm.applicants(v).ref());
      }}
      value={value?.id}
      {...props}
    >
      {data.map((applicant) => (
        <Select.Option value={applicant.email} key={applicant.email}>
          <Space align="center">
            <Avatar src={applicant.photo} size="small">
              {getInitials(applicant.name)}
            </Avatar>
            {applicant.name}
          </Space>
        </Select.Option>
      ))}
    </KSelect>
  );
};

export default ApplicantSelect;
