import useFs from './fs.hook';
import firebase from 'firebase';
import { capitalCase } from 'change-case';
import * as R from 'ramda';

const { useDebounce } = require('use-debounce/lib');

const getQuery = (query, key, value) =>
  query.where(key, '>=', value).where(key, '<=', `${value}\uf8ff`).limit(3);

const useNameSearch = (value, collection) => {
  const col = firebase.firestore().collection(collection);
  const q1 = getQuery(col, 'name', capitalCase(value));
  const q2 = getQuery(col, 'name', value.toLowerCase());
  const q3 = getQuery(col, 'email', value);

  const [data1, data1Loading] = useFs({
    query: q1,
    disabled: !value,
  });

  const [data2, data2Loading] = useFs({
    query: q2,
    disabled: !value,
  });

  const [data3, data3Loading] = useFs({
    query: q3,
    disabled: !value,
  });

  const data = R.pipe(
    R.map(R.or(R.__, [])),
    R.unnest,
    R.uniqBy(R.prop('email')),
  )([data1, data2, data3]);
  const loading = data1Loading || data2Loading || data3Loading;
  return [data, loading];
};

const useSearch = (text) => {
  const [value]: [String] = useDebounce(text, 300);
  const casesQuery = getQuery(
    firebase.firestore().collection('cases'),
    'id',
    value.toUpperCase(),
  );
  const [cases, casesLoading] = useFs({
    query: casesQuery,
    disabled: !value,
    populate: ['service', 'applicant'],
  });

  const [applicants, applicantsLoading] = useNameSearch(value, 'applicants');
  const [employees, employeesLoading] = useNameSearch(value, 'employees');
  return [
    {
      cases,
      applicants,
      employees,
    },
    casesLoading || applicantsLoading || employeesLoading,
  ];
};

export default useSearch;
