import KModal from '../k-modal';
import { Checkbox, Form, ModalProps } from 'antd';
import KForm from '@components/k-form';
import { emailPattern } from '@utils/form.utils';
import useModal from '@hooks/modal.hook';
import functionsService from '@services/functions.service';
import { useEffect, useState } from 'react';

type Props = ModalProps & {};

const InviteEmployeeModal = ({ ...props }: Props) => {
  const { visible, close } = useModal('invite');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (v) => {
    setLoading(true);
    await functionsService.sendInvite(
      {
        ...form.getFieldsValue(),
        url: window.location.origin,
      },
      'Invite sent',
    );
    setLoading(false);
    close();
  };
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <KModal
      visible={visible}
      {...props}
      title={'Invite Employee'}
      okText="Send Invite"
      onCancel={close}
      destroyOnClose
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <KForm
        form={form}
        onFinish={onSubmit}
        validateTrigger="onBlur"
        fields={[
          {
            label: 'Email',
            rules: [
              { required: true, message: 'Email required!' },
              {
                pattern: emailPattern,
                message: 'Invalid Email',
              },
            ],
            fieldKey: 'email',
            name: 'email',
            normalize: (v) => v.toLowerCase(),
          },
          {
            fieldKey: 'admin',
            label: 'Admin User',
            name: 'admin',
            component: Checkbox,
            valuePropName: 'checked',
          },
        ]}
      />
    </KModal>
  );
};

export default InviteEmployeeModal;
