import { colors } from '@constants/theme/colors.config';
import styled from '@emotion/styled';

export const SadaKSchedule = styled.div`
  .rbc-calendar {
    height: calc(100% - 64px);
    min-height: calc(100% - 64px);
    .rbc-timeslot-group {
      min-height: 60px;
    }
    .rbc-event-label {
      display: none;
    }
    .rbc-event {
      background: ${colors.blue};
    }
  }
`;
