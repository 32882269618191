import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import '@utils/firebase.utils';
import '@assets/odometer-theme-minimal.css';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
);
