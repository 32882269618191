import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { shadows } from '@constants/theme/shadows.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaSearchBarResults = styled.div`
  background: ${colors.white};
  padding: ${spacings.xxs} 0 ${spacings.md};
  box-shadow: ${shadows.sm};
  border-radius: ${radiuses.md};
`;
