import useAuth from '@hooks/auth.hook';
import useModal from '@hooks/modal.hook';
import fsOrm from '@services/firestore.service';
import StatusModal from '../status-modal';

const CaseStatusModal = () => {
  const { visible, close, data } = useModal('case-status');
  const { statuses = [], id } = data || {};
  const [user] = useAuth();
  const onSubmit = async (newStatus) => {
    await fsOrm.setStatus(newStatus, fsOrm.cases(id).ref(), user.email);
    close();
  };
  return (
    <StatusModal
      visible={visible}
      onCancel={close}
      title="Case Status History"
      flat
      statuses={statuses}
      onSubmit={onSubmit}
    />
  );
};

export default CaseStatusModal;
