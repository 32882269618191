import { SadaKAvatar } from '@components/k-avatar/styles';
import { shadows } from '@constants/theme/shadows.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import KModal from '../k-modal';

export const SadaCaseManagerModal = styled(KModal)`
  .ant-space {
    width: 100%;
  }
`;

export const CaseManagerCard = styled.div`
  padding: ${spacings.md} ${spacings.lg};
  display: flex;
  align-items: center;
  .ant-space {
    flex-grow: 1;
  }
  box-shadow: ${shadows.sm};
  ${SadaKAvatar.toString()} {
    margin-right: ${spacings.xl};
  }
`;
