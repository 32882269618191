import { Select } from 'antd';
import KSelect from '@components/k-select';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import { SelectProps } from 'antd';
import NameWithAvatar from '@components/name-with-avatar';
import type IService from '@types/service.type';
import useFs from '@hooks/fs.hook';

type Props = SelectProps & {
  value: firebase.firestore.DocumentReference,
  onChange: () => {},
  disabled: [String],
  service: IService,
};

const EmployeeSelect = ({
  value,
  onChange = () => {},
  disabled = [],
  service,
  ...props
}: Props) => {
  let query = firebase
    .firestore()
    .collection('employees')
    .where('status.status', '==', 'active');

  if (service) {
    query = query.where(
      'email',
      'in',
      service?.employees?.map(({ id }) => id),
    );
  }
  const [data, loading] = useFs({
    query,
  });
  return (
    <KSelect
      showSearch
      loading={loading}
      onChange={(v) => {
        onChange(fsOrm.employees(v).ref());
      }}
      value={value?.id}
      {...props}
    >
      {data?.map((employee) => (
        <Select.Option
          value={employee.email}
          key={employee.email}
          disabled={disabled.includes(employee.email)}
        >
          <NameWithAvatar {...employee} />
        </Select.Option>
      ))}
    </KSelect>
  );
};

export default EmployeeSelect;
