import KAvatar from '@components/k-avatar';
import Typography from '@components/typography';
import { SadaNameWithAvatar } from './styles';

type Props = { name: String, photo: String };

const NameWithAvatar = ({ name, photo, email }: Props) => (
  <SadaNameWithAvatar>
    <KAvatar {...{ name, photo }} />
    <Typography>{name || email} </Typography>
  </SadaNameWithAvatar>
);

export default NameWithAvatar;
