import SearchBar from '@components/search-bar';
import { SadaGlobalSearch } from './styles';

const GlobalSearch = () => (
  <SadaGlobalSearch>
    <SearchBar placeholder="Search by case name, ID, or company name" />
  </SadaGlobalSearch>
);

export default GlobalSearch;
