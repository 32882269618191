import KBtn from '@components/k-btn';
import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import { Modal, message } from 'antd';
import { CloseSquare, Danger, TickSquare } from 'react-iconly';
import { SadaCaseStatusButtons } from './styles';
import React from 'react';
import fsOrm from '@services/firestore.service';
import useAuth from '@hooks/auth.hook';

type Props = {
  status: any,
  id: String,
};
const iconStyle = { float: 'left', marginRight: spacings.md };

function CaseStatusButtons({ id, status }: Props) {
  const [user] = useAuth();
  const isClosed = ['cancelled', 'completed'].indexOf(status?.status) > -1;
  const [m, context] = Modal.useModal();
  const update = async (status) => {
    await fsOrm.setStatus(status, fsOrm.cases(id).ref(), user?.email);
    message.info(`Case marked as ${status}`);
  };
  const onComplete = async () => {
    m.confirm({
      title: 'Complete Case?',
      content:
        'Are you sure you want to mark case as completed? All the appointments are gonna be marked as completed and confirmation email will be sent to an applicant.',
      icon: <TickSquare primaryColor={colors.blue} style={iconStyle} />,
      okText: 'Complete',
      onOk: () => update('completed'),
    });
  };
  const onCancel = async () => {
    m.confirm({
      title: 'Close Case?',
      content:
        'Are you sure you want to mark case as closed? All the appointments are gonna be marked as rejected and cancellation email will be sent to an applicant.',
      icon: <CloseSquare primaryColor={colors.red} style={iconStyle} />,
      okText: 'Close Case',
      okButtonProps: { danger: true },
      onOk: () => update('cancelled'),
    });
  };
  const onReopen = async () => {
    m.confirm({
      title: 'Reopen Case?',
      content:
        'Are you sure you want to mark case as active? Email notification will be sent to an applicant.',
      icon: <Danger primaryColor={colors.orange} style={iconStyle} />,
      okText: 'Reopen',
      onOk: () => update('active'),
    });
  };

  return (
    <SadaCaseStatusButtons>
      {isClosed ? (
        <KBtn outline block onClick={onReopen}>
          Reopen Case
        </KBtn>
      ) : (
        <>
          <KBtn block outline secondary onClick={onComplete}>
            Complete Case
          </KBtn>

          <KBtn block outline danger onClick={onCancel}>
            Cancel Case
          </KBtn>
        </>
      )}
      {context}
    </SadaCaseStatusButtons>
  );
}

export default CaseStatusButtons;
