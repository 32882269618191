import KSelect from '@components/k-select';
import Typography from '@components/typography';
import { statusColors } from '@constants/theme/colors.config';
import { humanTimestamp, humanTimestampTime } from '@utils/date.utils';
import { Select, Space, Timeline } from 'antd';
import KModal from '../k-modal';
import * as R from 'ramda';
import { caseStatuses } from '@constants/status.config';
import { useState } from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import { UIFlexBox } from '@components/ui/layout';

const options = R.pipe(
  R.mapObjIndexed(({ text: label, color }, value) => ({ value, label })),
  R.values,
)(caseStatuses);

const StatusModal = ({ statuses = [], onSubmit = () => {}, ...props }) => {
  const [newStatus, setNewStatus] = useState(null);
  return (
    <KModal
      {...props}
      onOk={() => {
        onSubmit(newStatus);
      }}
      okButtonProps={{ disabled: !newStatus }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Timeline>
          {R.sortBy(R.prop('timestamp'))(statuses).map(
            ({ status, timestamp, employee }) => {
              return (
                <Timeline.Item
                  key={timestamp.toString()}
                  color={statusColors[caseStatuses[status]?.color]}
                >
                  <UIFlexBox justifyContent="space-between">
                    <Typography>{caseStatuses[status]?.text} </Typography>
                    {employee && (
                      <Typography sub xs>
                        {employee.id}
                      </Typography>
                    )}
                  </UIFlexBox>
                  <Typography sub>
                    {humanTimestamp(timestamp)} {humanTimestampTime(timestamp)}
                  </Typography>
                </Timeline.Item>
              );
            },
          )}
        </Timeline>
        <KSelect
          placeholder="Update status manually"
          value={newStatus}
          onChange={setNewStatus}
        >
          {options.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              <Space align="center">
                <Avatar
                  size={12}
                  style={{
                    background: statusColors[caseStatuses[value]?.color],
                  }}
                />
                {label}
              </Space>
            </Select.Option>
          ))}
        </KSelect>
      </Space>
    </KModal>
  );
};

export default StatusModal;
