import { SadaKRefColumn } from './styles';
import firebase from 'firebase';
import Typography from '@components/typography';
import { useDocumentData } from 'react-firebase-hooks/firestore';

type Props = {
  dataRef: firebase.firestore.DocumentReference,
  dataKey: String,
};

const KRefColumn = ({ dataRef, dataKey }: Props) => {
  const [data, loading] = useDocumentData(dataRef);
  return (
    <SadaKRefColumn>
      <Typography loading={loading}>{data?.[dataKey]}</Typography>
    </SadaKRefColumn>
  );
};

export default KRefColumn;
