import { SadaTable, SadaTableRow, SadaTableWrapper } from './styles';
import { TableProps } from 'antd';

type Props = { config: TableProps, filters: boolean };

const KDataTable = ({ config, children, filters }: Props) => (
  <SadaTable
    components={{
      body: {
        row: SadaTableRow,
        wrapper: SadaTableWrapper,
      },
    }}
    scroll={{x: '100%'}}
    {...config}
  />
);

export default KDataTable;
