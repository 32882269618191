import { SadaCaseTable } from './styles';
import { caseStatusFilters } from '@applicant/constants/case.config';
import KTabs from '@components/k-tabs';
import { UIBox, UIFlexBox, UISpaceBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { Button, Col, Row, Tabs } from 'antd';
import { spacings } from '@constants/theme/spacing.config';
import KDataTable from '@components/k-data-table';
import config from './config';
import KBtn from '@components/k-btn';
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { navigate } from 'hookrouter';

const CalendarButton = (
  <KBtn
    secondary
    onClick={() => {
      navigate('/schedule');
    }}
  >
    Calendar View
  </KBtn>
);
type Props = {
  query: firebase.firestore.Query,
  onRowClick: (String) => void,
  tinitialTabb: String,
};

const perPage = 25;
const CaseTable = ({
  query,
  onRowClick = (id) => {
    navigate(`/cases/${id}`);
  },
  initialTab = 'all',
}: Props) => {
  const [tab, setTab] = useState(initialTab);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [tab]);
  let filteredQuery = query.limit(perPage * page);

  if (tab === 'incomplete') {
    filteredQuery = filteredQuery.where('answers', '==', null);
  } else if (['pending', 'active', 'completed', 'cancelled'].includes(tab)) {
    filteredQuery = filteredQuery.where(
      'status.status',
      '==',
      tab.toLowerCase(),
    );
  }

  const [cases, loading] = useCollectionData(filteredQuery);
  return (
    <SadaCaseTable>
      <Row gutter={40}>
        <Col sm={24}>
          <UISpaceBox alignItems="center" justifyContent="space">
            <KTabs
              defaultActiveKey={initialTab}
              activeKey={tab}
              onChange={setTab}
              tabBarExtraContent={CalendarButton}
            >
              {caseStatusFilters.map((filter) => (
                <Tabs.TabPane tab={filter} key={filter} />
              ))}
            </KTabs>
          </UISpaceBox>
          <UIBox
            borderTop={`1px solid ${colors.almost_white}`}
            pt={spacings.xs}
          >
            <KDataTable
              config={{
                loading,
                dataSource: cases,
                rowKey: 'id',
                onRow: ({ id }) => ({
                  onClick: () => {
                    onRowClick(id);
                  },
                }),
                footer: () => (
                  <UIFlexBox justifyContent="center">
                    <Button
                      onClick={() => {
                        setPage(page + 1);
                      }}
                      size="middle"
                    >
                      Load More
                    </Button>
                  </UIFlexBox>
                ),
                ...config,
              }}
            />
          </UIBox>
        </Col>
      </Row>
    </SadaCaseTable>
  );
};

export default CaseTable;
