import useList from '@hooks/list.hook';
import { Select } from 'antd';
import type IService from '@types/service.type';
import KSelect from '@components/k-select';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';

type Props = {
  value: firebase.firestore.DocumentReference,
  onChange: () => {},
};

const ServiceSelect = ({ value, onChange = () => {} }: Props) => {
  const [data = [], loading]: [IService] = useList('services');
  return (
    <KSelect
      loading={loading}
      onChange={(v) => {
        onChange(fsOrm.services(v).ref());
      }}
      value={value?.id}
    >
      {data.map((service) => (
        <Select.Option value={service?.id} key={service?.id}>
          {service.title}
        </Select.Option>
      ))}
    </KSelect>
  );
};

export default ServiceSelect;
