import { Empty, EmptyProps } from 'antd';
import { SadaKEmpty } from './styles';
import { type ReactNode } from 'react';

type Props = EmptyProps & { image: ReactNode };

const KEmpty = ({ image = Empty.PRESENTED_IMAGE_SIMPLE, ...props }: Props) => (
  <SadaKEmpty image={image} {...props} />
);

export default KEmpty;
