import {
  SadaContent,
  SadaLayout,
  SadaScroll,
  SadaSider,
  SadaSiderBackdrop,
} from './styles';
import { useRoutes } from 'hookrouter';
import routesConfig from '../../constants/routes.config';
import NotFound from '@components/not-found';
import * as R from 'ramda';
import Header from './header';
import InviteEmployeeModal from '@components/modals/invite-employee-modal';
import Menu from '@components/menu';
import OrgCard from '@components/cards/org-card';
import { type IOrganization } from '@types/organization.type';
import useData from '@hooks/data.hook';
import { AnimatePresence } from 'framer-motion';
import { SlideIn } from '@components/ui/animations';
import ApplicantModal from '@components/modals/applicant-modal';
import CaseModal from '@components/modals/case-modal';
import AppointmentModal from '@components/modals/appointment-modal';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CaseManagerModal from '@components/modals/case-manager-modal';
import CaseStatusModal from '@components/modals/case-status-modal';
import ApplicatonAnswersModal from '@components/modals/applicaton-answers-modal';
import EditAppointmentModal from '@components/modals/edit-appointment-modal';
import useRoute from '@hooks/route.hook';
import { useStore } from 'react-context-hook';
import UserProfileDialog from '@components/modals/user-profile-dialog';
import CommentModal from '@components/modals/comment-modal';

const router = R.map(({ component: Component }) => (props) => (
  <Component {...props} key={Component.name} />
))(routesConfig);

const Layout = () => {
  const component = useRoutes(router);
  const current = useRoute(routesConfig);
  const [menu, setMenu] = useStore('menu', false);

  const [org, loading]: [IOrganization] = useData('organization', 'primary');
  return (
    <SadaLayout menu={menu}>
      <SadaSiderBackdrop
        onClick={() => {
          setMenu(false);
        }}
      />
      <SadaSider menu={menu}>
        <Menu
          routes={routesConfig}
          footer={<OrgCard {...org} loading={loading} />}
        />
      </SadaSider>
      <SadaContent>
        <Header />
        {component ? (
          <SadaScroll full={current?.full}>
            {/* <PerfectScrollbar> */}
            <AnimatePresence exitBeforeEnter>
              <SlideIn key={component.key}>{component}</SlideIn>
            </AnimatePresence>
            {/* </PerfectScrollbar> */}
          </SadaScroll>
        ) : (
          <NotFound />
        )}
      </SadaContent>
      <InviteEmployeeModal />
      <ApplicantModal />
      <CaseModal />
      <AppointmentModal />
      <CaseManagerModal />
      <CaseStatusModal />
      <ApplicatonAnswersModal />
      <EditAppointmentModal />
      <UserProfileDialog />
      <CommentModal />
    </SadaLayout>
  );
};
export default Layout;
