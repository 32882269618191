import { Button, Dropdown, Menu } from 'antd';
import { ChevronDown } from 'react-iconly';
import { MenuItemActive, SadaCalendarSelect } from './styles';

type Props = {};

const CalendarSelect = ({ selected, setSelected = () => {} }: Props) => {
  const onSelect = ({ key }) => {
    setSelected(key);
  };

  const calendars = [
    { key: 'employee', label: 'My Calendar' },
    { key: 'all', label: 'All Calendars' },
  ];
  return (
    <SadaCalendarSelect>
      <Dropdown
        overlay={
          <Menu onClick={onSelect}>
            {calendars.map(({ key, label }) => (
              <MenuItemActive key={key} active={key === selected}>
                {label}
              </MenuItemActive>
            ))}
          </Menu>
        }
      >
        <Button type="link" icon={<ChevronDown size="small" />} iconright>
          {calendars.find(({ key }) => key === selected)?.label}
        </Button>
      </Dropdown>
    </SadaCalendarSelect>
  );
};

export default CalendarSelect;
