import KSchedule from '@components/k-schedule';
import { SadaSchedule } from './styles';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const Schedule = () => {
  return (
    <SadaSchedule>
      <KSchedule initial="employee" />
    </SadaSchedule>
  );
};

export default Schedule;
