import KHeading from '@components/k-heading';
import KIconButton from '@components/k-icon-button';
import useFs from '@hooks/fs.hook';
import useModal from '@hooks/modal.hook';
import { Chat } from 'react-iconly';
import { SadaCommentsSection } from './styles';
import firebase from 'firebase';
import KComment from '@components/k-comment';
import KEmpty from '@components/k-empty';
import { useState } from 'react';
import * as R from 'ramda';
import { Button } from 'antd';
import { UIFlexBox } from '@components/ui/layout';

type Props = { caseId: string };

const CommentsSection = ({ caseId }: Props) => {
  const [expand, setExpand] = useState(false);
  const [comments] = useFs({
    query: firebase
      .firestore()
      .collection('cases')
      .doc(caseId)
      .collection('comments')
      .orderBy('timestamp'),
    populate: ['employee'],
  });
  const { open } = useModal('comment');
  const show = expand ? comments : R.take(3)(comments || []);
  return (
    <SadaCommentsSection>
      <KHeading
        title="Comments"
        extra={
          <KIconButton
            icon={<Chat />}
            onClick={() => {
              open({ caseId });
            }}
          />
        }
      />
      {comments?.length ? (
        <div>
          {show.map((comment) => (
            <KComment {...comment} key={comment.id} caseId={caseId} />
          ))}
          {comments?.length > 3 && (
            <UIFlexBox justifyContent="center">
              <Button
                type="link"
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand ? 'Show less' : 'Show more'}
              </Button>
            </UIFlexBox>
          )}
        </div>
      ) : (
        <KEmpty description="" />
      )}
    </SadaCommentsSection>
  );
};

export default CommentsSection;
