import useModal from '@hooks/modal.hook';
import KModal from '../k-modal';
import { Form, Input, ModalProps } from 'antd';
import { useEffect, useState } from 'react';
import KForm from '@components/k-form';
import EmployeeSelect from '@components/data-selects/employee-select';
import KDatePicker from '@components/k-date-picker';
import AppointmentTimeSelect from '@components/data-selects/appointment-time-select';
import { employeeValidator } from '@utils/form.utils';
import functionsService from '@services/functions.service';
import fsOrm from '@services/firestore.service';
import firebase from 'firebase';
import { getDisabledDate } from '@utils/date.utils';
import type IAppointment from '@types/appointment.type';

type Props = ModalProps & {};

const EditAppointmentModal = ({ ...props }: Props) => {
  const { visible, close, data }: { data: IAppointment } = useModal(
    'edit-appointment',
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [date, setDate] = useState(null);
  useEffect(() => {
    if (visible) {
      form.resetFields();
      setDate(data?.start?.toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  const onSubmit = async (v) => {
    setLoading(true);
    const event = {
      update: {
        employee: v?.employee?.id,
        applicant: data?.applicant?.id,
        start: v?.time?.start,
        end: v?.time?.end,
        comments: v?.comments,
      },
      eventId: data?.eventId,
      calendarId: data?.service?.calendarId,
    };
    await functionsService.editEvent(event);
    await fsOrm.appointments(data?.id).update({
      comments: v?.comments,
      employee: v?.employee,
      start: firebase.firestore.Timestamp.fromDate(new Date(v?.time?.start)),
      end: firebase.firestore.Timestamp.fromDate(new Date(v?.time?.end)),
    });
    await fsOrm.setStatus('rescheduled', fsOrm.appointments(data?.id).ref());
    await fsOrm.activity().create({
      appointment: fsOrm.appointments(data?.id).ref(),
      employee: v?.employee,
      applicant: v?.employee,
      action: 'rescheduled',
    });
    setLoading(false);
    close();
  };

  const watchDate = async (v) => {
    const [
      {
        name: [name],
        value,
      },
    ] = v || [{}];
    if (name === 'date') {
      setDate(value);
      form.setFieldsValue({ time: null });
    } else if (name === 'time') {
      setUsers(value?.employees);
    }
  };
  return (
    <KModal
      visible={visible}
      {...props}
      title={'Edit Appointment'}
      okText="Submit"
      onCancel={close}
      destroyOnClose
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <KForm
        form={form}
        onFinish={onSubmit}
        validateTrigger="onBlur"
        onFieldsChange={watchDate}
        fields={[
          {
            label: 'Employee',
            fieldKey: 'employee',
            name: 'employee',
            component: EmployeeSelect,
            componentProps: { service: data?.service },
            initialValue: data?.employee,
            rules: [
              {
                validator: employeeValidator(users),
              },
              { required: true },
            ],
          },
          {
            label: 'Date',
            fieldKey: 'date',
            name: 'date',
            component: KDatePicker,
            initialValue: data?.start?.toDate(),
            componentProps: {
              allowClear: false,
              disabledDate: getDisabledDate(data?.service?.schedule),
            },
          },
          {
            label: 'Time',
            fieldKey: 'time',
            name: 'time',
            component: AppointmentTimeSelect,
            componentProps: {
              loading: true,
              date,
              service: data?.service,
              select: true,
            },
            rules: [{ required: true }],
          },
          {
            label: 'Comments',
            fieldKey: 'comments',
            name: 'comments',
            component: Input.TextArea,
          },
        ]}
      />
    </KModal>
  );
};

export default EditAppointmentModal;
