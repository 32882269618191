import ApplicantSelect from '@components/data-selects/applicant-select';
import EmployeeSelect from '@components/data-selects/employee-select';
import ServiceSelect from '@components/data-selects/service-select';
import KForm from '@components/k-form';
import useModal from '@hooks/modal.hook';
import fsOrm from '@services/firestore.service';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import KModal from '../k-modal';
import { newId } from '@utils/firebase.utils';
import { navigate } from 'hookrouter';

type Props = {};

const CaseModal = ({ ...props }: Props) => {
  const { visible, close } = useModal('case');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  const onSubmit = async (v) => {
    setLoading(true);
    const newCase = await fsOrm
      .cases()
      .create({ ...v, id: await newId(), answers: null }, 'Case created');
    await fsOrm.setStatus('pending', newCase);
    setLoading(false);
    close();
    navigate(`/cases/${newCase.id}`);
  };
  return (
    <KModal
      visible={visible}
      {...props}
      title={'New Case'}
      okText="Create"
      onCancel={close}
      destroyOnClose
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <KForm
        form={form}
        onFinish={onSubmit}
        fields={[
          {
            label: 'Service',
            name: 'service',
            component: ServiceSelect,
            rules: [{ required: true }],
          },
          {
            label: 'Applicant',
            name: 'applicant',
            component: ApplicantSelect,
            rules: [{ required: true }],
          },
          {
            label: 'Employee',
            name: 'employee',
            component: EmployeeSelect,
            rules: [{ required: true }],
          },
        ]}
      />
    </KModal>
  );
};

export default CaseModal;
