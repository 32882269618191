import { colors } from '@constants/theme/colors.config';
import screens from '@constants/theme/screens.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Tabs } from 'antd';

export const SadaKTabs = styled(Tabs)`
  width: 100%;
  .ant-tabs-tab {
    min-width: 100px;
    justify-content: center;
    margin-right: 0;
  }
  .ant-tabs-nav-wrap {
    padding: 0 ${spacings.xl};
  }
  .ant-tabs-tab-btn {
    color: ${colors.dark_gray};
  }
  ${screens.mobile} {

    .ant-tabs-tab {
      min-width: auto;
      justify-content: center;
      margin-right: 0;
    }
  }
`;
