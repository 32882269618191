import useModal from '@hooks/modal.hook';
import KModal from '../k-modal';
import { Form, Input, ModalProps } from 'antd';
import { useEffect, useState } from 'react';
import KForm from '@components/k-form';
import ApplicantSelect from '@components/data-selects/applicant-select';
import EmployeeSelect from '@components/data-selects/employee-select';
import KDatePicker from '@components/k-date-picker';
import AppointmentTimeSelect from '@components/data-selects/appointment-time-select';
import { employeeValidator } from '@utils/form.utils';
import functionsService from '@services/functions.service';
import AppointmentTypeSelect from '@components/data-selects/appointment-type-select';
import fsOrm from '@services/firestore.service';
import firebase from 'firebase';
import { newId } from '@utils/firebase.utils';
import { getDisabledDate } from '@utils/date.utils';

type Props = ModalProps & {};

const AppointmentModal = ({ ...props }: Props) => {
  const { visible, close, data } = useModal('appointment');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  const onSubmit = async (v) => {
    setLoading(true);
    const event = {
      service: data?.service?.id,
      employee: v?.employee?.id,
      applicant: v?.applicant?.id,
      start: v?.time?.start,
      end: v?.time?.end,
      comments: v?.comments,
      type: v?.type,
    };
    const caseRef = fsOrm.cases(data?.id).ref();
    const calendarEvent = await functionsService.createEvent(event);

    const aptRef = await fsOrm.appointments().create({
      eventId: calendarEvent?.id,
      meetUrl: calendarEvent.hangoutLink,
      comments: v?.comments,
      type: v?.type,
      employee: v?.employee,
      applicant: v?.applicant,
      case: caseRef,
      service: fsOrm.services(data?.service?.id).ref(),
      start: firebase.firestore.Timestamp.fromDate(new Date(v?.time?.start)),
      end: firebase.firestore.Timestamp.fromDate(new Date(v?.time?.end)),
      created: firebase.firestore.Timestamp.now(),
      id: await newId(),
    });
    await fsOrm.setStatus('booked', aptRef);
    await fsOrm.setStatus('active', caseRef);
    const appointments = data?.appointments || [];
    await fsOrm
      .cases(data.id)
      .update({ appointments: [...appointments, aptRef] });
    setLoading(false);
    close();
  };
  const [date, setDate] = useState(null);

  const watchDate = async (v) => {
    const [
      {
        name: [name],
        value,
      },
    ] = v || [{}];
    if (name === 'date') {
      setDate(value);
      form.setFieldsValue({ time: null });
    } else if (name === 'time') {
      setUsers(value?.employees);
    }
  };
  return (
    <KModal
      visible={visible}
      {...props}
      title={'New Appointment'}
      okText="Create"
      onCancel={close}
      destroyOnClose
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <KForm
        form={form}
        onFinish={onSubmit}
        validateTrigger="onBlur"
        onFieldsChange={watchDate}
        fields={[
          {
            label: 'Applicant',
            fieldKey: 'applicant',
            name: 'applicant',
            component: ApplicantSelect,
            initialValue: data?.applicant,
            componentProps: {
              disabled: !!data?.applicant,
            },
          },
          {
            label: 'Type',
            name: 'type',
            fieldKey: 'type',
            component: AppointmentTypeSelect,
            componentProps: {
              service: data?.service,
            },
            initialValue: data?.service?.types?.[0],
          },
          {
            label: 'Employee',
            fieldKey: 'employee',
            name: 'employee',
            component: EmployeeSelect,
            initialValue: data?.employee,
            componentProps: { service: data?.service },
            rules: [
              {
                validator: employeeValidator(users),
              },
              { required: true },
            ],
          },
          {
            label: 'Date',
            fieldKey: 'date',
            name: 'date',
            component: KDatePicker,
            componentProps: {
              allowClear: false,
              disabledDate: getDisabledDate(data?.service?.schedule),
            },
          },
          {
            label: 'Time',
            fieldKey: 'time',
            name: 'time',
            component: AppointmentTimeSelect,
            componentProps: {
              loading: true,
              date,
              service: data?.service,
              select: true,
            },
            rules: [{ required: true }],
          },
          {
            label: 'Comments',
            fieldKey: 'comments',
            name: 'comments',
            component: Input.TextArea,
          },
        ]}
      />
    </KModal>
  );
};

export default AppointmentModal;
