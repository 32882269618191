import { Col, Row } from 'antd';
import { SadaCases } from './styles';
import { navigate } from 'hookrouter';
import KHeading from '@components/k-heading';
import { PaperPlus } from 'react-iconly';
import useModal from '@hooks/modal.hook';
import CaseTable from '@components/case-table';
import firebase from 'firebase';
import { useQueryParams } from 'hookrouter';

const Cases = () => {
  const { open } = useModal('case');
  const onRowClick = (id) => {
    navigate(`/cases/${id}`);
  };
  const [queryParams] = useQueryParams();
  const query = firebase
    .firestore()
    .collection('cases')
    .orderBy('created', 'desc');
  const { tab } = queryParams;
  return (
    <SadaCases>
      <Row gutter={40}>
        <Col sm={24}>
          <KHeading title="Cases" onAction={open} icon={<PaperPlus />} xl />
          <CaseTable onRowClick={onRowClick} query={query} initialTab={tab} />
        </Col>
      </Row>
    </SadaCases>
  );
};

export default Cases;
