import { type ICase } from '@types/case.type';
import { setHours } from 'date-fns';

export const caseStatusFilters = [
  'all',
  'pending',
  'active',
  'completed',
  'cancelled',
  'incomplete',
];

export const caseMocks: [ICase] = [
  {
    id: 'test',
    completed: false,
    organization: 'Planet Express',
    created: new Date(),
    title: 'DL Renewal',
    start: setHours(new Date(), 10),
    end: setHours(new Date(), 12),
    type: 'Interview',
  },
  {
    id: 'test2',
    completed: false,
    organization: 'Planet Express',
    created: new Date(),
    title: 'DL Renewal',
    start: setHours(new Date(), 10),
    end: setHours(new Date(), 12),
    type: 'Interview',
  },
  {
    id: 'test3',
    completed: true,
    organization: 'Planet Express',
    created: new Date(),
    title: 'DL Renewal',
    start: setHours(new Date(), 10),
    end: setHours(new Date(), 12),
    type: 'Interview',
  },
  {
    id: 'test4',
    completed: true,
    organization: 'Planet Express',
    created: new Date(),
    title: 'DL Renewal',
    start: setHours(new Date(), 10),
    end: setHours(new Date(), 12),
    type: 'Interview',
  },
];
