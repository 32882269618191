import Typography from '@components/typography';
import useModal from '@hooks/modal.hook';
import { CaseManagerCard, SadaCaseManagerModal } from './styles';
import KAvatar from '@components/k-avatar';
import { Space } from 'antd';
import KIconButton from '@components/k-icon-button';
import { Delete } from 'react-iconly';
import EmployeeSelect from '@components/data-selects/employee-select';
import fsOrm from '@services/firestore.service';

const CaseManagerModal = () => {
  const { visible, close, data, setData } = useModal('case-manager');
  const { employee, caseId } = data || {};
  const value = fsOrm.employees(employee?.email);
  const onChange = async (value) => {
    await fsOrm.cases(caseId).update({ employee: value });

    if (value) {
      close();
    } else {
      setData({ ...data, employee: null });
    }
  };

  return (
    <SadaCaseManagerModal
      visible={visible}
      onCancel={close}
      title="Reassign Specialist"
      flat
    >
      <Space direction="vertical" size={20}>
        <Typography bold md>Current Employment Services Specialist</Typography>
        {employee ? (
          <CaseManagerCard>
            <KAvatar {...employee} size={64} />
            <Space direction="vertical" size={0}>
              <Typography bold>{employee.name}</Typography>
              <Typography sm>{employee.email}</Typography>
            </Space>
            <KIconButton
              icon={<Delete />}
              danger
              onClick={() => {
                onChange(null);
              }}
            />
          </CaseManagerCard>
        ) : (
          <div>
            <EmployeeSelect value={value} onChange={onChange} />
          </div>
        )}
        {!employee && (
          <Typography sm>
            {' '}
            There is not employee specialist assigned.
          </Typography>
        )}
      </Space>
    </SadaCaseManagerModal>
  );
};

export default CaseManagerModal;
