import Login from '@components/login';
import { UIBox } from '@components/ui/layout';
import Layout from './layout';
import useAuth from '@hooks/auth.hook';
import { withStore } from 'react-context-hook';
import useData from '@hooks/data.hook';
import AccessError from '@components/access-error';

const App = () => {
  const [user, loading] = useAuth();
  const [employee, employeeLoading] = useData('employees', user?.email);
  return (
    <UIBox height="100%">
      {!user ? (
        <Login authLoading={loading} disabled />
      ) : employee ? (
        <Layout />
      ) : (
        !employeeLoading && <AccessError />
      )}
    </UIBox>
  );
};

export default withStore(App);
