import UserCard from '@components/cards/user-card';
import KHeading from '@components/k-heading';
import useAuth from '@hooks/auth.hook';
import { Col, Row, Space } from 'antd';
import { SadaDashboard } from './styles';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import { ReactComponent as EmptyCases } from '@/assets/images/empty-cases.svg';
import useFs from '@hooks/fs.hook';
import KCardList from '@components/lists/k-card-list';
import * as R from 'ramda';
import { getCaseContentString } from '@utils/case.utils';
import StatusTag from '@components/status-tag';
import { navigate } from 'hookrouter';
import ActivityList from '@components/activity-list';
import { startOfMinute, subMinutes } from 'date-fns';
import { getApptTimeStatus } from '@utils/appointment.utils';

const Dashboard = () => {
  const [user] = useAuth();

  const [data, loading] = useFs({
    query: firebase
      .firestore()
      .collection('appointments')
      .where('employee', '==', fsOrm.employees(user.email).ref())
      .where('start', '>', subMinutes(startOfMinute(new Date()), 45))
      .orderBy('start', 'asc'),
    populate: ['service', 'applicant'],
  });
  const activityQuery = firebase
    .firestore()
    .collection('activity')
    .where('employee', '==', fsOrm.employees(user.email).ref());

  return (
    <SadaDashboard>
      <Row gutter={24}>
        <Col sm={14} xs={24}>
          <KHeading title="Upcoming Appointments" />
          <KCardList
            config={{
              title: R.path(['applicant', 'name']),
              photo: R.path(['applicant', 'photo']),
              subTitle: R.path(['service', 'title']),
              content: getCaseContentString,
              action: ({ status }) => <StatusTag flat {...status} />,
              status: getApptTimeStatus,
            }}
            onClick={(id) => {
              navigate(`/cases/${data.find(R.propEq('id', id))?.case?.id}`);
            }}
            emptyImage={<EmptyCases />}
            emptyText="No upcoming appointments"
            loading={loading}
            data={data}
          />
        </Col>
        <Col sm={10} xs={24}>
          <Space direction="vertical" size={24}>
            <UserCard user={user} />
            <ActivityList query={activityQuery} />
          </Space>
        </Col>
      </Row>
    </SadaDashboard>
  );
};

export default Dashboard;
