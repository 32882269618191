import { SadaAccessError, SadaAccessErrorBox } from './styles';
import KBtn from '@components/k-btn';
import { logout } from '@utils/firebase.utils';
import { Alert } from 'antd';

const AccessError = () => (
  <SadaAccessError>
    <SadaAccessErrorBox>
      <Alert
        type="error"
        message="Unauthorized"
        description="Access is denied due to insufficient permissions. Please
        contact administrator."
      ></Alert>
      <KBtn onClick={logout}>Sign Out</KBtn>
    </SadaAccessErrorBox>
  </SadaAccessError>
);

export default AccessError;
