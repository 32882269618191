import { SadaAppointmentTypeSelect } from './styles';
import { Divider, SelectProps, Space } from 'antd';
import KSelect from '@components/k-select';
import KBtn from '@components/k-btn';
import { useRef, useState } from 'react';
import KInput from '@components/k-input';
import { UIBox } from '@components/ui/layout';
import { PlusOutlined } from '@ant-design/icons';
import fsOrm from '@services/firestore.service';
import type IService from '@types/service.type';

type Props = {
  value: String,
  onChange: () => {},
  service: IService,
} & SelectProps;

const AppointmentTypeSelect = ({ service, ...props }: Props) => {
  const [newType, setNewType] = useState('');
  const [loading, setLoading] = useState(false);
  const types = service?.types || [];
  const ref = useRef();
  const addNewType = async () => {
    setLoading(true);
    await fsOrm.services(service?.id).update({ types: [...types, newType] });
    props.onChange?.(newType);
    setLoading(false);
    ref.current.focus();
  };
  return (
    <SadaAppointmentTypeSelect>
      <input
        type="text"
        tabIndex="-1"
        ref={ref}
        style={{ position: 'absolute', opacity: 0, zIndex: 0 }}
      />
      <KSelect
        options={types.map((key) => ({ value: key, label: key }))}
        defaultActiveFirstOption
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <UIBox padding="8px 12px">
              <Space>
                <KInput
                  value={newType}
                  onChange={({ target }) => {
                    setNewType(target.value);
                  }}
                />
                <KBtn
                  icon={<PlusOutlined />}
                  secondary
                  disabled={!newType}
                  onClick={addNewType}
                  loading={loading}
                >
                  Add Type
                </KBtn>
              </Space>
            </UIBox>
          </div>
        )}
        {...props}
      />
    </SadaAppointmentTypeSelect>
  );
};

export default AppointmentTypeSelect;
