import { SadaOrgCard, SadaOrgCardContent, SadaOrgCardCover } from './styles';
import { type IOrganization } from '@types/organization.type';
import { Space } from 'antd';
import Typography from '@components/typography';
import KBtn from '@components/k-btn';
import { navigate } from 'hookrouter';

type Props = IOrganization & { editable: boolean, loading: boolean };

const OrgCard = ({ splash, name, editable, loading }: Props) => (
  <SadaOrgCard>
    <SadaOrgCardCover image={!loading && splash} />
    <SadaOrgCardContent>
      <Space direction="vertical" size={12} align="center">
        <Typography md medium loading={loading && { width: 100 }}>
          {name}
        </Typography>
        {editable && (
          <KBtn
            secondary="true"
            outline
            disabled={loading}
            onClick={() => {
              navigate('/settings/org');
            }}
          >
            Edit Profile
          </KBtn>
        )}
      </Space>
    </SadaOrgCardContent>
  </SadaOrgCard>
);

export default OrgCard;
