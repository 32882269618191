import KRefColumn from '@components/k-data-table/k-ref-column';
import KTimestamp from '@components/k-timestamp';
import StatusTag from '@components/status-tag';
import { TableProps } from 'antd';

const columns = [
  {
    render: ({ id }) => `#${id}`,
    key: 'id',
    title: 'Case ID',
  },
  {
    key: 'applicant',
    render: ({ applicant }) => (
      <KRefColumn dataRef={applicant} dataKey="name" />
    ),
    title: 'Applicant Name',
  },
  {
    key: 'employee',
    render: ({ employee }) => <KRefColumn dataRef={employee} dataKey="name" />,
    title: 'Case Manager',
  },
  {
    key: 'service',
    render: ({ service }) => <KRefColumn dataRef={service} dataKey="title" />,
    title: 'Type',
  },
  {
    key: 'timestamp',
    render: KTimestamp,
    title: 'Date Created',
  },
  {
    render: ({ status }) => <StatusTag {...status} />,
    key: 'status',
    title: 'Status',
  },
];

const config: TableProps = {
  columns,
  pagination: false
};

export default config;
