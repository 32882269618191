import KAvatar from '@components/k-avatar';
import Typography from '@components/typography';
import { Space } from 'antd';
import { SadaSearchBarResult } from './styles';

type Props = {
  photo: String,
  title: String,
  description: String,
  onClick: () => {},
};

const SearchBarResult = ({ photo, title, description, onClick }: Props) => (
  <SadaSearchBarResult size="large" align="center" onClick={onClick}>
    <KAvatar photo={photo} title={title} size={36} />
    <Space direction="vertical" size={0}>
      <Typography md bold>
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </Space>
  </SadaSearchBarResult>
);

export default SearchBarResult;
