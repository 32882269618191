import { Filter, Search } from 'react-iconly';
import KIconButton from '../k-icon-button';
import KInput from '../k-input';
import { SadaSearchBar } from './styles';
import React, { useState } from 'react';
import { Dropdown } from 'antd';
import SearchBarResults from './search-bar-results';
import useSearch from '@hooks/search.hook';

type Props = {
  placeholder: String,
  filters: boolean,
};

const SearchBar = ({ placeholder, filters }: Props) => {
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, loading] = useSearch(search);
  return (
    <SadaSearchBar size="large">
      <Dropdown
        overlay={
          <SearchBarResults
            {...data}
            onSelect={() => {
              setVisible(false);
              setSearch('');
            }}
          />
        }
        visible={visible && focus}
        loading={loading}
      >
        <KInput
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setFocus(false);
            }, 100);
          }}
          allowClear
          prefix={<Search />}
          placeholder={placeholder}
          value={search}
          onChange={({ target }) => {
            const { value } = target;

            setSearch(value);
            if (value) {
              setVisible(true);
            } else {
              setVisible(false);
            }
          }}
        />
      </Dropdown>

      {filters && <KIconButton icon={<Filter />} />}
    </SadaSearchBar>
  );
};

export default SearchBar;
