import { SadaKTimestamp } from './styles';
import firebase from 'firebase';
import { humanTimestamp } from '@utils/date.utils';

type Props = {
  timestamp: firebase.firestore.Timestamp,
};

const KTimestamp = ({ timestamp }: Props) => (
  <SadaKTimestamp>{humanTimestamp(timestamp)}</SadaKTimestamp>
);

export default KTimestamp;
