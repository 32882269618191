import { SadaKScheduleEvent } from './styles';
import * as R from 'ramda';
import NameWithAvatar from '@components/name-with-avatar';
import { Popover, Space } from 'antd';
import Typography from '@components/typography';
import { humanTimestampTime } from '@utils/date.utils';
import KBtn from '@components/k-btn';
import React from 'react';
import { navigate } from 'hookrouter';

type Props = {};

const KScheduleEvent = ({ event, onClose }: Props) => {
  const applicant = R.path(['appointment', 'applicant'])(event);
  const employee = R.path(['appointment', 'employee'])(event);
  const title = R.path(['appointment', 'type'])(event);
  const start = R.path(['appointment', 'start'])(event);
  const end = R.path(['appointment', 'end'])(event);
  const caseId = R.path(['appointment', 'case', 'id'])(event);
  return (
    <Popover
      visible={event?.active}
      title={title}
      content={
        <Space direction="vertical" size={24}>
          <Space direction="vertical">
            <Typography>
              {humanTimestampTime(start)} - {humanTimestampTime(end)}
            </Typography>
            <NameWithAvatar {...applicant} />
            <NameWithAvatar {...employee} />
          </Space>
          <Space>
            <KBtn
              size="small"
              secondary
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Close
            </KBtn>
            <KBtn
              size="small"
              onClick={() => {
                navigate(`/cases/${caseId}`);
              }}
            >
              View
            </KBtn>
          </Space>
        </Space>
      }
    >
      <SadaKScheduleEvent>
        <NameWithAvatar {...applicant} />
      </SadaKScheduleEvent>
    </Popover>
  );
};

export default KScheduleEvent;
