import screens from '@constants/theme/screens.config';
import styled from '@emotion/styled';

export const SadaCaseTable = styled.div`
  .ant-tabs-tab-btn {
    text-transform: capitalize;
  }
  ${screens.mobile} {
    .ant-tabs-extra-content {
      display: none;
    }
  }
`;
