import { SadaBtn } from '@components/k-btn/styles';
import { UICenterBox, UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaAccessError = styled(UICenterBox)`
  background: ${colors.almost_white};
`;

export const SadaAccessErrorBox = styled(UIFlexBox)`
  justify-content: center;
  align-items: center;
  width: 500px;
  max-width: 100vw;
  padding: ${spacings.lg};
  background: ${colors.white};
  border-radius: ${radiuses.md};
  flex-direction: column;
  .ant-space {
    width: 100%;
  }
  ${SadaBtn.toString()} {
    margin-top: ${spacings.lg};
  }
`;
