import { UIFlexBox } from '@components/ui/layout';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Select, Space } from 'antd';

export const SadaSearchBar = styled(Space)`
  margin: ${spacings.sm} 0;
  display: flex;
  > .ant-space-item:first-of-type {
    flex-grow: 1;
  }
`;

export const SadaSearchBarInput = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${radiuses.md};
    background: ${colors.almost_white};
    border: none;
    & > input::placeholder {
      color: ${colors.gray_0};
    }
    .ant-input-prefix {
      font-size: 20px;
      margin-right: ${spacings.md};
    }
    .ant-select-selection-placeholder {
      .ant-space-item {
        height: 24px;
      }
    }
  }
`;

export const SadaSearchPlaceholder = styled(UIFlexBox)`
  align-items: center;
  height: 40px;
  svg {
    margin-right: ${spacings.md};
    color: ${colors.almost_black};
    font-size: 20px;
  }
`;
