import { type IMenuItem } from '@/types/menu.type';

import { Category, TwoUsers, Work, Calendar } from 'react-iconly';

import Applicants from '@/apps/employee/components/applicants';
import Cases from '@/apps/employee/components/cases';
import Dashboard from '@/apps/employee/components/dashboard';
import Schedule from '@/apps/employee/components/schedule';
import Applicant from '../components/applicants/applicant';
import Case from '../components/cases/case';

const routesConfig: IMenuItem = {
  '/': {
    component: Dashboard,
    label: 'Dashboard',
    path: '/',
    icon: Category,
  },
  '/cases': {
    component: Cases,
    label: 'Cases',
    path: '/cases',
    icon: Work,
  },
  '/cases/:id': {
    component: Case,
    label: 'Case Details',
    path: '/cases',
    hideMenu: true,
    match: /\/cases\/(?<id>\w+)/,
  },
  '/applicants': {
    component: Applicants,
    label: 'Applicants',
    path: '/applicants',
    icon: TwoUsers,
  },
  '/applicants/:id': {
    component: Applicant,
    label: 'Applicant Details',
    path: '/applicants',
    hideMenu: true,
    match: /\/applicants\/(?<id>\w+)/,
  },
  '/schedule': {
    component: Schedule,
    full: true,
    label: 'Schedule',
    path: '/schedule',
    icon: Calendar,
  },
};

export default routesConfig;
